/* onBoardingPalette:  ['#0A2342', '#9B2C2C', '#F4A261', '#ffbe98'], */

.k2d {
  font-family: 'K2D';
  color: #0A2342
}

.secondary {
  color: #9B2C2C;
}

.tertiary {
  color: #F4A261;
}

.quaternary {
  color: #ffbe98;
}

.white {
  color: #fff;
}

.ctaLabel {
  color: #fff;
  font-weight: "bold";
}

.a:hover{
  color: inherit;
}

/* Custom button style */
.button.bg-button {
  background-color: rgba(159, 159, 159, 0.7); /* Initial background color with 0.7 opacity */
  color: white; /* Initial text color */
}

/* Style for the valid state */
.button.bg-button-valid {
  background-color: #9B2C2C !important; /* Valid background color */
  color: white !important; /* Text color */
}

/* Style for the disabled state */
.button.bg-button:disabled {
  background-color: rgba(159, 159, 159, 0.7); /* Disabled background color with 0.7 opacity */
  color: white; /* Disabled text color */
}

.bg-testimonial {
  opacity: 0.4;
  background: #ffbe98;
}

.highlight {
  background-color: #0A2342;
  color: #fff
}

h2 {
  font-family: "Montserrat";
  font-weight: 400;
}

h3 {
  font-family: "ZonaPro-Bold";
  text-align: center;
}

.card-item {
  border-radius: 1rem;
  height: 300px;
}

  @media screen and (max-width: 768px) {
    .animation {
      font-size: 30px;
      text-align: "left";
    }
    .big-title {
      font-size: 100px;
      text-align: left;
    }
    .smallP {
      font-size: 14px;
    }
    p {
      font-family: "Montserrat";
      text-align: left;
      font-size: 15px;
    }
    h1 {
      font-size: 50px;
      text-align: left;
    }
    h2 {
      text-align: left;
    }
    h3 {
      text-align: left;
    }
    .desc {
      font-size: 14px;
    }
    .testimonial-desc {
      font-size: 14px;
    }
  }

  @media screen and (min-width: 769px) {
    .animation {
      font-size: 45px;
    }
    .testimonial-desc {
      font-size: 15px;
    }
    .big-title {
      font-size: 120px;
    }
    p {
      font-family: "Montserrat";
      text-align: left;
      font-size: 18px;
    }
    h1 {
      font-family: "ZonaPro-Bold";
      font-size: 60px;
      text-align: left;
    }
    .desc {
      font-size: 16px;
    }
  }

  .level-is-shrinkable {
      flex-shrink: 1;
  }

  .maxBox {
    width:100%;
  }

  .faq-container {
    height: auto;
    width: auto;
  }

  .card-section {
    height: 200px;
    overflow: auto;
  }


.is-valigned {
    display: flex;
    flex-wrap: wrap;
    align-content: center; /* used this for multiple child */
    align-items: center; /* if an only child */
  }

  .productDetail {
    font-family: "Montserrat";
    font-size: 14px;
  }

  .coachImage {
    height: 180px;
    width: 180px;
  }

  .priceTag {
    height: 50px;
    width: 200px;
    background-color: #878CFF;
    border-radius: 25px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .coachCard {
    border-radius: 15px;
  }

  .coachCardSelected {
    border-radius: 15px;
    border-width: 3px;
    background-color: #DDFFFA;
  }

  .coachCard:hover {
    background-color: #F9F9F9;
  }

  .paymentForm {
    border: 1.5px solid #E8E8E8;
    border-radius: 4px;
    padding: 12px 4px;
  }

  .places {
    border-radius: 4px;
    padding: 12px 4px;
    background-color: #DDFFFA;
  }

  .error {
    color: #F03A5F;
  }